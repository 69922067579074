import React, { Component } from 'react';
import { is } from 'ramda';

import withFieldWrapper from '../hocs/withFieldWrapper';

class Radio extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value, name }} = this.props;
        const options = is(Array, this.props.options) ? this.props.options : [];

        return options.map((option, index) =>
            <label className='radio' key={`${name}-${index}`}>
                <input
                    type='radio'
                    value={option}
                    checked={option === value}
                    onChange={this.onChange} /><span className="checked-box" /> { option }
            </label>
        );
    }
}

export default withFieldWrapper(Radio);
