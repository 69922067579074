import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { pathOr, path, fromPairs } from 'ramda';
import Twig from 'twig';

import Number from './formComponents/Number';
import Select from './formComponents/Select';
import Radio from './formComponents/Radio';
import Input from './formComponents/Input';
import Checkbox from './formComponents/Checkbox';
import { ButtonLoader } from './Loader';

const FIELDS = {
    input: Input,
    number: Number,
    select: Select,
    radio: Radio,
    checkbox: Checkbox
};

class FormField extends Component {
    getHiddenQuestionTemplate = () => {
        const hideCondition = path(['hideCondition'], this.props.field);

        if (!hideCondition) {
            return null;
        }

        if (!this.hiddenQuestionTemplate) {
            this.hiddenQuestionTemplate = Twig.twig({
                data: hideCondition
            });
        }

        return this.hiddenQuestionTemplate;
    }

    isHiddenQuestion = () => {
        const hiddenQuestionTemplate = this.getHiddenQuestionTemplate();

        return hiddenQuestionTemplate ?
            hiddenQuestionTemplate.render({
                value: this.props.formValues[this.props.field.key],
                ...fromPairs(this.props.fields.map(({ key }) => ([ key, this.props.formValues[key] ]))),
                form: fromPairs(this.props.fields.map(({ key, title }) => ([ title, this.props.formValues[key] ])))
            }) === 'true' : false;
    }

    getFieldProps = () => {
        const { language, field, index, translation } = this.props;
        const { options, title } = field;

        return {
            options: (options || []).map((name, i) => language ? pathOr(name, ['options', i], translation[index]) : name),
            label: language ? pathOr(title, [index, 'title'], translation) : title
        };
    }

    render() {
        const { field } = this.props;

        return this.isHiddenQuestion() ? null :
            <Field
                name={field.key}
                component={FIELDS[field.type]}
                fieldType={field.type}
                validate={value => !value && field.required ? 'required' : undefined}
                clearOnUnmount
                {...this.getFieldProps()} />;
    }
}

export default class RespondentForm extends Component {
    onSubmit = sections => {
        this.props.postRespondentStart.dispatch({
            id: this.props.id,
            sections
        });
    }

    render() {
        const { fields, language, getRespondent, translation, postRespondentStart } = this.props;
        const buttonTranslation = pathOr('Начать опрос', ['translation', 'pageElements', 'startButton'], getRespondent.data.publicProject);

        return <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit, invalid, values }) =>
                <form onSubmit={handleSubmit}>
                    { fields.map((field, index) =>
                        <FormField
                            field={field}
                            key={field.key}
                            index={index}
                            language={language}
                            formValues={values}
                            fields={fields}
                            translation={translation} />
                    )}
                    <button className='btn' type='submit' disabled={invalid || postRespondentStart.meta.pending} id='start'>
                        <ButtonLoader loading={postRespondentStart.meta.pending}>
                            { language ? pathOr(buttonTranslation, ['publicProject', 'translation', language, 'startButton'], getRespondent.data) : buttonTranslation}
                        </ButtonLoader>
                    </button>
                </form>
            } />;
    }
}
