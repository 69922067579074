import { pathOr } from 'ramda';
import React, { Component } from 'react';

import withQuestionFieldWrapper from '../hocs/withQuestionFieldWrapper';

class Textarea extends Component {
    onChange = e => {
        const { value } = e.target;

        this.props.onChange(value);
    }

    getPlaceholder() {
        const { language, respondent } = this.props;

        return pathOr('Ответ', ['translation', language, 'placeholder'], respondent.publicProject);
    }

    render() {
        const { value, disabled } = this.props;

        return <textarea
            value={value || ''}
            disabled={disabled}
            placeholder={this.getPlaceholder()}
            onChange={this.onChange} />;
    }
}

export default withQuestionFieldWrapper(Textarea);
