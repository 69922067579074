import React, { Component } from 'react';
import { pathOr, contains, path, fromPairs, all, addIndex, isNil } from 'ramda';
import cx from 'classnames';
import { Form, Field } from 'react-final-form';
import { withAsyncActions } from 'react-async-client';
import { toast } from 'react-toastify';
import Twig from 'twig';

import { putRespondentQuestion } from '../actions/asyncActions';
import NoMatch from './NoMatch';
import Textarea from './questionFormComponents/Textarea';
import Individual from './questionFormComponents/Individual';
import Matrix from './questionFormComponents/Matrix';
import Single from './questionFormComponents/Single';
import Drag from './questionFormComponents/Drag';
import Select from './questionFormComponents/Select';
import Slider from './questionFormComponents/Slider';
import { isDemo } from '../utils/demo';
import { isComment } from '../utils/fields';
import NPS from './questionFormComponents/NPS';

const FIELDS = {
    single: Single,
    drag: Drag,
    textarea: Textarea,
    text: Textarea,
    individual: Individual,
    matrix: Matrix,
    slider: Slider,
    select: Select,
    nps: NPS
};

const CHOOSE_TEXT = 'Ниже представлен ряд утверждений, которые Вам предлагается оценить. Выберите насколько Вы согласны со следующими утверждениями';

const required = value => isNil(value) ? 'required' : undefined;

const VALIDATE = question => ({
    single: value => {
        return addIndex(all)((item, index) => isComment(item) ? true : !isNil(path([index], value)), question.questions) ? undefined : 'required';
    },
    drag: value => {
        const min = path(['options', 'minAnswers'], question);
        return !isNil(min) ? (value || []).length < min : required(value)
    },
    textarea: value => path(['options', 'allowEmpty'], question) ? undefined : required(value),
    individual: required,
    matrix: required,
    slider: required,
    select: required,
    nps: value => addIndex(all)((_, index) => !isNil(path([index], value)), question.questions) ? undefined : 'required'
});

const TITLE_FROM_QUESTIONS = ['textarea', 'individual'];
const TITLE_FROM_NAME = ['drag'];

const INITIAL_VALUES = {
    select: question => fromPairs(question.questions.map((_, index) => ([index, 0]))),
    nps: question => fromPairs(question.questions.map((_, index) => ([index, undefined]))),
    slider: question => question.questions.map(() => 50)
};

class Question extends Component {
    state = {
        initialValues: {}
    };

    componentDidMount() {
        const { getRespondent: { data }, match: { params: { num }}, id } = this.props;
        const pages = pathOr([], ['publicProject', 'drivers'], data);
        const answers = pathOr([], ['response', 'answers'], data);
        const question = pathOr({}, ['publicProject', 'drivers', Number(num)], data);

        this.setState({
            initialValues: {
                answers: answers[num] || (INITIAL_VALUES[question.type] ? INITIAL_VALUES[question.type](question) : null),
                num,
                id,
                finish: Number(num) + 1 >= pages.length
            }
        });
    }

    getQuestion = () => {
        const { match: { params: { num }}, getRespondent: { data }} = this.props;
        const drivers = pathOr([], ['publicProject', 'drivers'], data);

        return drivers[num];
    }

    getTranslation = () => {
        const { language, getRespondent: { data }, match: { params: { num }}} = this.props;

        return pathOr({}, ['publicProject', 'translation', 'drivers', num, language], data);
    }

    onSubmit = values => {
        if (!isDemo(this.props.location)) {
            this.props.putRespondentQuestion.dispatch({
                ...values,
                answers: !values.answers ? [''] : values.answers
            });
        }
    }

    getHiddenQuestionTemplate = () => {
        const hideQuestions = path(['options', 'hideQuestions'], this.getQuestion());

        if (!hideQuestions) {
            return null;
        }

        if (!this.hiddenQuestionTemplate) {
            this.hiddenQuestionTemplate = Twig.twig({
                data: hideQuestions
            });
        }

        return this.hiddenQuestionTemplate;
    }

    onChangePage = index => {
        const { location, history, id } = this.props;

        if (isDemo(location)) {
            history.push(`/respondents/${id}/driver/${index}${location.search}`);
        }
    }

    getScale = () => {
        const { getRespondent: { data }, language } = this.props;
        const question = this.getQuestion();
        const translation = this.getTranslation();
        const questionScale = language ? pathOr([], ['scale'], translation) : pathOr([], ['scale'], question);
        const projectScale = language ? pathOr([], ['publicProject', 'translation', language, 'scale'], data) : pathOr([], ['publicProject', 'scale'], data);

        return questionScale.length ? questionScale : projectScale;
    }

    render = () => {
        const { match: { params: { num }}, getRespondent: { data }, language, putRespondentQuestion } = this.props;
        const question = this.getQuestion();
        const pages = pathOr([], ['publicProject', 'drivers'], data);
        const answers = pathOr([], ['response', 'answers'], data);
        const translation = this.getTranslation();
        const buttonTranslation = pathOr('Ответить', ['translation', 'pageElements', 'submitButton'], data.publicProject);
        const chooseTextTranslation = pathOr(CHOOSE_TEXT, ['translation', 'pageElements', 'chooseText'], data.publicProject);
        const questionTitle = (language && translation.title ? translation.title : question.title) || (
            contains(question.type, TITLE_FROM_QUESTIONS) ? path(['questions', 0], language ? translation : question) :
            contains(question.type, TITLE_FROM_NAME) ? (language ? translation.name : question.name) :
            pathOr(chooseTextTranslation, ['publicProject', 'translation', language, 'chooseText'], data)) || chooseTextTranslation;

        return <div className={`question-${num}`}>
            <h1 className='question-title' dangerouslySetInnerHTML={{ __html: language ? pathOr(data.publicProject.title, ['translation', language, 'title'], data.publicProject) : data.publicProject.title }} />
            <div className='question' key={`question-${num}`}>
                <h3 dangerouslySetInnerHTML={{ __html: questionTitle }} id='question-title' />
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={this.state.initialValues}
                    subscription={{ submitting: true, invalid: true, submitFailed: true }}
                    render={({ handleSubmit, invalid }) =>
                        <form className='answer' onSubmit={handleSubmit}>
                            <Field
                                name='answers'
                                component={FIELDS[question.type]}
                                validate={VALIDATE(question)[question.type]}
                                respondent={data}
                                hiddenQuestionTemplate={this.getHiddenQuestionTemplate()}
                                question={question}
                                translation={translation}
                                language={language}
                                scale={this.getScale()}
                                disabled={putRespondentQuestion.meta.pending}
                                options={question.options || {}} />
                            <button className='btn' type='submit' disabled={invalid || putRespondentQuestion.meta.pending} id='submit'>
                                { language ? pathOr(buttonTranslation, ['publicProject', 'translation', language, 'submitButton'], data) : buttonTranslation}
                            </button>
                        </form>
                    } />
                <div className="pagination">
                    { pages.map((_, index) =>
                        <span
                            key={`page-${index}`}
                            className={cx({ current: Number(num) === index, answered: index < answers.length })}
                            onClick={() => this.onChangePage(index)}>
                            { index + 1 }
                        </span>
                    )}
                </div>
            </div>
        </div>;
    }
}

class QuestionWrapper extends Component {
    render() {
        const { getRespondent: { meta }, match: { params: { num }}} = this.props;

        return meta.pending && !meta.lastSucceedAt ?
            <div>Loading...</div> :
            meta.error ? <NoMatch /> :
            !!meta.lastSucceedAt && <Question key={num} {...this.props} />;
    }
}

export default withAsyncActions({
    putRespondentQuestion: putRespondentQuestion
        .withSuccessHandler(({ match: { params: { num }}, getRespondent, history, id }) => {
            const next = Number(num) + 1;
            const pages = pathOr([], ['publicProject', 'drivers'], getRespondent.data);

            history.push(next >= pages.length ? `/respondents/${id}/thanks` : `/respondents/${id}/driver/${next}`);
        })
        .withErrorHandler(() => toast.error('Не удалось отправить ответ на вопрос'))
        .withOptions({ resetOnUnmount: true })
})(QuestionWrapper);
