import React, { Component } from 'react';
import { path, filter, pathOr } from 'ramda';

export default WrappedComponent =>
    class FieldWrapper extends Component {
        componentWillUnmount() {
            if (this.props.clearOnUnmount) {
                this.onChange(null);
            }
        }

        onChange = value => {
            this.props.input.onChange(value);
        };

        isHiddenQuestion = (question, index) => {
            const { respondent, hiddenQuestionTemplate } = this.props;

            return hiddenQuestionTemplate ?
                hiddenQuestionTemplate.render({
                    question,
                    questionIndex: index,
                    additionalData: respondent.additionalData,
                    project: respondent.publicProject,
                    answers: path(['response', 'answers'], respondent)
                }) === 'true' : false;
        }

        render() {
            const questions = filter((item, index) => !this.isHiddenQuestion(item, index), pathOr([], ['questions'], this.props.question));
            const translatedQuestions = filter((item, index) => !this.isHiddenQuestion(item, index), pathOr([], ['questions'], this.props.translation));

            return <div>
                { this.props.fieldType !== 'checkbox' && <div dangerouslySetInnerHTML={{ __html: this.props.label }} /> }
                <WrappedComponent
                    {...this.props}
                    questions={questions}
                    translatedQuestions={translatedQuestions}
                    onChange={this.onChange} />
            </div>
        }
    }
