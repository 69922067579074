import createSagaMiddle from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { pushRollbarError } from '../utils/rollbar';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddle({
    onError: pushRollbarError
});

const middlewares = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    middlewares.push(createLogger({ collapsed: true }));
}

export default middlewares;
