import React, { Component } from 'react';
import { pathOr } from 'ramda';

export default class Thanks extends Component {
    render() {
        const { language, getRespondent: { data }} = this.props;
        const finishTitleTranslation = pathOr('Опрос завершен', ['translation', 'pageElements', 'finishTitle'], data.publicProject);
        const finishTextTranslation = pathOr('Спасибо за участие!', ['translation', 'pageElements', 'finishText'], data.publicProject);

        return <div className='finish'>
            <span className="check-icon" />
            <h3>
                { language ? pathOr(finishTitleTranslation, ['publicProject', 'translation', language, 'finishTitle'], data) : finishTitleTranslation }
            </h3>
            <h4>
                { language ? pathOr(finishTextTranslation, ['publicProject', 'translation', language, 'finishText'], data) : finishTextTranslation }
            </h4>
        </div>;
    }
}
