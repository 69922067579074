import React, { Component, Fragment } from 'react';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { pathOr, filter, findIndex, isNil, toPairs, path, isEmpty } from 'ramda';
import { Switch } from 'react-router-dom';
import { takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { withStateHandlers } from 'recompose';
import styled from 'styled-components';

import { getRespondent, postRespondentStart, postRespondentFinish } from '../actions/asyncActions';
import NoMatch from './NoMatch';
import RespondentForm from './RespondentForm';
import Question from './Question';
import { PUT_RESPONDENT_QUESTION } from '../constants/actionTypes';
import Thanks from './Thanks';
import { isDemo } from '../utils/demo';
import { setTheme } from '../utils/cssVariables';
import Route from './Route';
import Loader, { ButtonLoader } from './Loader';

const LanguageSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const LanguageSelector = styled.div`
    width: 300px;
    background: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

class RespondentMain extends Component {
    renderInfoSection = publicProject => {
        const { language } = this.props;
        const title = language ? pathOr(publicProject.title, ['translation', this.props.language, 'title'], publicProject) : publicProject.title;
        const welcomeText = language ? pathOr(publicProject.welcomeText, ['translation', this.props.language, 'welcomeText'], publicProject) : publicProject.welcomeText;

        return (title || welcomeText) ?
            <div className='welcome-text'>
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                <p className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: welcomeText }} />
            </div> : null;
    }

    start = () => {
        this.props.postRespondentStart.dispatch({
            id: this.props.id
        });
    }

    renderForm = sections => {
        const buttonTranslation = pathOr('Начать опрос', ['translation', 'pageElements', 'startButton'], this.props.getRespondent.data.publicProject);

        return sections.length ?
            <RespondentForm
                id={this.props.id}
                postRespondentStart={this.props.postRespondentStart}
                getRespondent={this.props.getRespondent}
                fields={sections}
                language={this.props.language}
                translation={pathOr([], ['data', 'publicProject', 'translation', this.props.language, 'sections'], this.props.getRespondent)} /> :
                <button className='btn' type='button' onClick={this.start} id='start' disabled={this.props.postRespondentStart.meta.pending}>
                    <ButtonLoader loading={this.props.postRespondentStart.meta.pending}>
                        { this.props.language ? pathOr(buttonTranslation, ['publicProject', 'translation', this.props.language, 'startButton'], this.props.getRespondent.data) : buttonTranslation}
                    </ButtonLoader>
                </button>;
    }

    goToQuestion = () => {
        const { id, getRespondent, history } = this.props;
        const index = findIndex(isNil, pathOr([], ['response', 'answers'], getRespondent.data));

        history.push(`/respondents/${id}/driver/${index < 0 ? 0 : index}`);
    }

    renderData = () => {
        const { data } = this.props.getRespondent;
        const publicProject = pathOr({}, ['publicProject'], data);
        const buttonTranslation = pathOr('Продолжить', ['translation', 'pageElements', 'continueButton'], publicProject);

        switch (data.status) {
            case 'sent':
                return this.renderForm(publicProject.sections || []);
            case 'started':
                return <button className='btn' type='button' onClick={this.goToQuestion}>
                    { this.props.language ? pathOr(buttonTranslation, ['publicProject', 'translation', this.props.language, 'continueButton'], data) : buttonTranslation}
                </button>;
            default:
                return <NoMatch />;
        }
    }

    render() {
        const { meta, data } = this.props.getRespondent;
        const publicProject = pathOr({}, ['publicProject'], data);

        return meta.pending && !meta.lastSucceedAt ?
            <div>Loading...</div> :
            meta.error ? <NoMatch /> :
            <div className='main-box'>
                { this.renderInfoSection(publicProject) }
                { this.renderData() }
            </div>;
    }
}

class Respondent extends Component {
    onChangeLanguage = e => {
        const defaultLanguage = path(['publicProject', 'defaultLanguage'], this.props.getRespondent.data);
        const value = e.target.value;

        this.props.setLanguage(value === defaultLanguage ? null : value);
    }

    selectLanguage = language => {
        const defaultLanguage = path(['publicProject', 'defaultLanguage'], this.props.getRespondent.data);

        this.props.setLanguage(language === defaultLanguage ? null : language);
    }

    render() {
        const { getRespondent, postRespondentStart, match: { params: { id }}, languageSelected, language } = this.props;
        const languages = toPairs(pathOr({}, ['publicProject', 'languages'], getRespondent.data));
        const defaultLanguage = path(['publicProject', 'defaultLanguage'], getRespondent.data);
        const css = path(['publicProject', 'projectSettings', 'theme', 'css'], getRespondent.data);
        const chooseLanguageFirst = path(['publicProject', 'projectSettings', 'theme', 'chooseLanguageFirst'], getRespondent.data);

        return <Fragment>
            { css && <style>{ css }</style> }
            { getRespondent.meta.pending && !getRespondent.meta.lastSucceedAt ?
                <Loader /> :
                !languageSelected && !!languages.length && !getRespondent.data.language ?
                    <LanguageSelectorWrapper>
                        <LanguageSelector>
                            { path(['publicProject', 'translation', defaultLanguage, 'chooseLanguage'], getRespondent.data) ||
                                path(['publicProject', 'translation', 'pageElements', 'chooseLanguage'], getRespondent.data) ||
                                'Выберите язык'
                            }
                            { languages.map(([ id, name ]) =>
                                <button onClick={() => this.selectLanguage(id)} className='btn' key={id}>{ name }</button>
                            )}
                        </LanguageSelector>
                    </LanguageSelectorWrapper> :
                    <div className="container mx-auto">
                        <header className='header mb-5'>
                            <span className="logo" />
                            { !!languages.length && !chooseLanguageFirst && !getRespondent.data.language &&
                                <div className='language-selector'>
                                    <select value={language || defaultLanguage} onChange={this.onChangeLanguage}>
                                        { languages.map(([ id, name ]) =>
                                            <option key={`language-option-${id}`} value={id}>
                                                { name }
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }
                        </header>
                        <Switch>
                            <Route path={`/respondents/${id}`} exact render={props =>
                                <RespondentMain
                                    {...props}
                                    id={id}
                                    postRespondentStart={postRespondentStart}
                                    getRespondent={getRespondent}
                                    language={language} />
                            } />
                            <Route path={`/respondents/${id}/driver/:num`} render={props =>
                                <Question {...props} id={id} getRespondent={getRespondent} language={language} />
                            } />
                            <Route path={`/respondents/${id}/thanks`} render={props =>
                                <Thanks {...props} id={id} getRespondent={getRespondent} language={language} />
                            } />
                            <Route component={NoMatch} />
                        </Switch>
                    </div>
            }
        </Fragment>;
    }
}

export default withStateHandlers({
    languageSelected: false,
    language: null
}, {
    setLanguageSelected: () => languageSelected => ({ languageSelected }),
    setLanguage: () => language => ({ language, languageSelected: true })
})(
    withAsyncActions({
        postRespondentStart: postRespondentStart
            .withSuccessHandler(({ postRespondentStart: { data: { id }}, history, getRespondent, postRespondentFinish }) => {
                const pages = pathOr([], ['publicProject', 'drivers'], getRespondent.data);

                if (!pages.length) {
                    postRespondentFinish.dispatch(id);
                } else {
                    history.push(`/respondents/${id}/driver/0`);
                }
            })
            .withErrorHandler(() => toast.error('Не удалось начать опрос'))
            .withOptions({ resetOnUnmount: true }),
        postRespondentFinish: postRespondentFinish
            .withSuccessHandler(({ history, match: { params: { id }}}) => history.push(`/respondents/${id}/thanks`))
            .withErrorHandler(() => toast.error('Не удалось завершить опрос'))
            .withOptions({ resetOnUnmount: true }),
        getRespondent: getRespondent
            .withPayload(({ match: { params: { id }}}) => id)
            .withSuccessHandler(({ getRespondent, postRespondentFinish, match: { params: { id }}, location, languageSelected, setLanguageSelected, setLanguage }) => {
                const pages = pathOr([], ['publicProject', 'drivers'], getRespondent.data);
                const answers = filter(answer => !!answer, pathOr([], ['response', 'answers'], getRespondent.data));
                const theme = path(['publicProject', 'projectSettings', 'theme'], getRespondent.data);
                const defaultLanguage = path(['publicProject', 'defaultLanguage'], getRespondent.data);

                if (answers.length === pages.length && getRespondent.data.status !== 'sent' && !isDemo(location)) {
                    postRespondentFinish.dispatch(id);
                }

                if (theme && !isEmpty(theme)) {
                    setTheme(theme.name || 'ancorTheme', theme.logo);
                }

                if (getRespondent.data.language) {
                    setLanguage(getRespondent.data.language === defaultLanguage ? null : getRespondent.data.language);
                }

                if (!languageSelected && !path(['chooseLanguageFirst'], theme)) {
                    setLanguageSelected(true);
                }
            })
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(PUT_RESPONDENT_QUESTION)], () => {
                    getProps().getRespondent.refresh();
                })
            })
            .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
    })(Respondent)
);
