import React, { Component, Fragment } from 'react';
import { SagaProvider } from 'react-async-client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { history, sagaMiddleware } from '../store/middlewares';
import NoMatch from './NoMatch';
import Respondent from './Respondent';
import { pushRollbarError } from '../utils/rollbar';
import Route from './Route';
import ScrollToTop from './ScrollToTop';

export default class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ error: true });

        pushRollbarError(error, errorInfo);
    }

    render() {
        const { store } = this.props;

        return this.state.error ?
            <div>Не удалось запустить приложение</div> :
            <Fragment>
                <ToastContainer />
                <SagaProvider sagaMiddleware={sagaMiddleware}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <ScrollToTop>
                                <Switch>
                                    <Route path='/respondents/:id' component={Respondent} />
                                    <Route component={NoMatch} />
                                </Switch>
                            </ScrollToTop>
                        </ConnectedRouter>
                    </Provider>
                </SagaProvider>
            </Fragment>;
    }
}
