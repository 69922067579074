import React, { Component } from 'react';
import RcSlider from 'rc-slider';
import { pathOr, assocPath, dissocPath, isEmpty, isNil } from 'ramda';
import styled from 'styled-components';
import tinygradient from 'tinygradient';

import withFieldWrapper from '../hocs/withFieldWrapper';
import imgEmodji from '../../assets/emoji.png';

const gradient = tinygradient([
    '#ff4500',
    'gold',
    '#75c464'
]);

const EmojiSlider = styled.section`
    margin: 64px auto;
    width: 100%;
    position: relative;
    .rc-slider .rc-slider-rail {
        background-image: linear-gradient(to right, ${({ gradient }) => gradient});
        height: 4px;
    }
    .rc-slider .rc-slider-track {
        background: transparent;
    }
    .b-mood-slider-handle {
        position: absolute;
        border-radius: 50%;
        cursor: pointer;
        cursor: -webkit-grab;
        cursor: grab;
        border: none;
        width: 32px;
        height: 32px;
        margin-top: -15px;
        margin-left: -17px;
    }
    .b-mood-slider-edge {
        position: absolute;
        width: 32px;
        height: 32px;
        top: -8px;
    }
    .b-mood-slider-edge__left {
        left: -17px;
    }
    .b-mood-slider-edge__right {
        right: -15px;
    }
    .bg-frowning_face {
        background-position: -2px 1px;
    }
    .bg-slightly_frowning_face {
        background-position: -2px -83px;
    }
    .bg-neutral_face {
        background-position: -2px -55px;
    }
    .bg-smiling_face {
        background-position: -2px -112px;
    }
    .bg-grinning_face {
        background-position: -2px -27px;
    }
    .emoji {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -32px;
        left: 4px;
        background-size: 28px;
        background-repeat: no-repeat;
        background-image: url(${imgEmodji});
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    }
    .b-mood-slider-handle:after {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 8px;
        height: 8px;
        background: #fff;
        border-radius: 4px;
        content: '';
    }
    .b-mood-slider-handle span.emoji {
        top: -30px;
    }
    .rc-slider-dot {
        border-color: var(--color-elements);
        width: 12px;
        height: 12px;
        bottom: -4px;
        margin-left: -6px;
    }
`;

class Slider extends Component {
    getPointerColor = (value, step) => {
        const colors = gradient.rgb((100 / step) + 1).map(color => color.toHexString());

        return colors[(value * (100 / step)) / 100] || '#FFD700';
    }

    getEmoju = value => {
        return value <= 20 ? 'bg-frowning_face' :
            value <= 40 ? 'bg-slightly_frowning_face' :
            value <= 60 ? 'bg-neutral_face' :
            value <= 80 ? 'emoji bg-smiling_face' : 'bg-grinning_face';
    }

    renderHandle = props => {
        return <div
            className='b-mood-slider-handle'
            style={{ ...props.style, left: `${props.value / props.max * 100}%` }}>
            <span className={`emoji ${this.getEmoju(props.value)}`}></span>
        </div>;
    }

    onChange = (value, index) => {
        const current = this.props.input.value || [];
        const values = !isNil(value) ? assocPath([index], value, current) : dissocPath([index], current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    needShowLeftEmoji(value) {
        return value > 4;
    }

    needShowRightEmoji(value, max) {
        return value < 96;
    }

    getGradientString = amount => {
        const { options } = this.props;

        if (options.gradient) {
            return '#ff4500, gold, #75c464';
        }

        const colors = gradient.rgb(amount).map(color => color.toHexString());
        const step = 100 / amount;

        return colors.reduce((res, cur, index) => res + (
            index === colors.length - 1 ? `${cur} ${step * index}%` :
            !index ? `${cur} ${step * (index + 1)}%, ` :
            `${cur} ${step * index }%, ${cur} ${step * (index + 1)}%, `
        ), '');
    }

    renderItem = (question, index) => {
        const { language, translatedQuestions, input, disabled, options, respondent } = this.props;
        const value = pathOr(0, [index], input.value);
        const step = pathOr(1, ['step'], options);
        const knowNothingTranslation = pathOr('Не знаю', ['translation', 'pageElements', 'knowNothing'], respondent.publicProject);

        return <div className='answer-row-slider' key={`question-${index}`}>
            <div className='answer-text' dangerouslySetInnerHTML={{ __html: language && translatedQuestions[index] ? translatedQuestions[index] : question }} />
            <div className="answer-slider-box slider-element">
                { value !== -1 &&
                    <EmojiSlider gradient={this.getGradientString(100 / step)}>
                        { this.needShowLeftEmoji(value) &&
                            <div className='b-mood-slider-edge b-mood-slider-edge__left'>
                                <span className='emoji bg-frowning_face' />
                            </div>
                        }
                        { this.needShowRightEmoji(value) &&
                            <div className='b-mood-slider-edge b-mood-slider-edge__right'>
                                <span className='emoji bg-grinning_face' />
                            </div>
                        }
                        <RcSlider
                            min={0}
                            max={100}
                            step={step}
                            disabled={disabled}
                            value={value}
                            onChange={value => this.onChange(value, index)}
                            handleStyle={{ background: this.getPointerColor(value, step) }}
                            handle={props => this.renderHandle(props)}
                            dots={options.dots} />
                    </EmojiSlider>
                }
            </div>
            <label className='checkbox answer-input'>
                <input
                    type='checkbox'
                    checked={value === -1}
                    disabled={disabled}
                    onChange={e => this.onChange(e.target.checked ? -1 : 50, index)} /><span className="checked-box" /> { language ? pathOr(knowNothingTranslation, ['publicProject', 'translation', language, 'knowNothing'], respondent) : knowNothingTranslation}
            </label>
        </div>;
    }

    render() {
        const { questions } = this.props;

        return questions.map(this.renderItem);
    }
}

export default withFieldWrapper(Slider);
