import React from 'react';
import styled from 'styled-components';

const Spinner = styled.svg`
    animation: rotator 1.4s linear infinite;

    @keyframes rotator {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(270deg); }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 187;
        }
        50% {
            stroke-dashoffset: 46.75;
            transform: rotate(135deg);
        }
        100% {
            stroke-dashoffset: 187;
            transform: rotate(450deg);
        }
    }

    circle {
        stroke-dasharray: 187;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: dash 1.4s ease-in-out infinite;
        stroke: var(--primary);
    }
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export default () =>
    <Center>
        <Spinner width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </Spinner>
    </Center>;

export const ButtonLoader = ({ children, loading }) =>
    loading ?
        <div style={{ display: 'flex' }}>
            <Spinner width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle fill="none" strokeWidth="6" style={{ stroke: "rgb(108, 108, 108)" }} strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </Spinner>
            <div style={{ margin: 0, marginLeft: 15 }}>{ children }</div>
        </div> :
        children;
