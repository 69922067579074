import React, { Component } from 'react';

import withFieldWrapper from '../hocs/withFieldWrapper';

class Number extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value }} = this.props;

        return <input
            type='number'
            value={value}
            onChange={this.onChange} />;
    }
}

export default withFieldWrapper(Number);
