import React, { Component } from 'react';
import { pathEq, assocPath, pathOr } from 'ramda';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import cx from 'classnames';

import withFieldWrapper from '../hocs/withFieldWrapper';
import { isComment } from '../../utils/fields';

const CommentMobileWrapper = styled.div`
    text-align: center;
    margin-bottom: 15px;
`;

class Comment extends Component {
    state = {
        opened: false
    };

    open = () => {
        this.setState({ opened: true });
        setTimeout(() => this.textarea.focus());
    }

    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { value, disabled, commentTranslation } = this.props;

        return this.state.opened ?
            <textarea
                ref={node => this.textarea = node}
                value={value}
                disabled={disabled}
                onChange={this.onChange} /> :
            <span className='comment' onClick={this.open}>{ commentTranslation }</span>;
    }
}

class Single extends Component {
    onChange = (value, index) => {
        const values = assocPath([index], value, this.props.input.value || []);
        this.props.onChange(values);
    }

    render() {
        const { questions, input: { value = [] }, scale, language, translatedQuestions, disabled, respondent } = this.props;
        const commentTranslation = pathOr('Комментарий', ['translation', language, 'commentButton'], respondent.publicProject);

        return isMobile ?
            <div>
                { questions.map((question, index) =>
                    isComment(question) ?
                        <CommentMobileWrapper key={`comment-${index}`}>
                            <Comment
                                value={(value || [])[index]}
                                disabled={disabled}
                                onChange={value => this.onChange(value, index)}
                                commentTranslation={commentTranslation} />
                        </CommentMobileWrapper> :
                        <div key={`question-${index}`} className='mb-4'>
                            <div dangerouslySetInnerHTML={{ __html: language && translatedQuestions[index] ? translatedQuestions[index] : question }} />
                            <select
                                className='mt-2'
                                disabled={disabled}
                                onChange={e => this.onChange(Number(e.target.value), index)} value={value[index]}>
                                <option></option>
                                { scale.map((variant, i) =>
                                    <option key={`variant-${i}`} value={i}>
                                        { variant }
                                    </option>
                                )}
                            </select>
                        </div>
                )}
            </div> :
            <table>
                <thead>
                    <tr>
                        <th></th>
                        { scale.map((variant, index) =>
                            <th width='10%' key={`variant-${index}`}>{ variant }</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    { questions.map((question, index) =>
                        <tr key={`question-${index}`}>
                            <td
                                className={cx({ 'answer-text-no-comment': isComment(questions[index + 1]) })}
                                dangerouslySetInnerHTML={{ __html: isComment(question) ? '' : (language && translatedQuestions[index] ? translatedQuestions[index] : question) }} />
                            { isComment(question) ?
                                <td colSpan={scale.length} className='text-center'>
                                    <Comment
                                        value={(value || [])[index]}
                                        disabled={disabled}
                                        onChange={value => this.onChange(value, index)}
                                        commentTranslation={commentTranslation} />
                                </td> :
                                scale.map((variant, i) =>
                                    <td
                                        width='10%'
                                        className={ isComment(questions[index + 1]) ? 'text-center' : 'answer-no-comment' }
                                        key={`variant-${index}-${i}`}>
                                        <label className='radio' id={`answer-${index}-${i}`}>
                                            <input
                                                type='radio'
                                                checked={pathEq([index], i, value)}
                                                value={i}
                                                disabled={disabled}
                                                onChange={e => this.onChange(Number(e.target.value), index)} />
                                            <span className="checked-box" />
                                        </label>
                                    </td>
                                )
                            }
                        </tr>
                    )}
                </tbody>
            </table>;
    }
}

export default withFieldWrapper(Single);
