import React, { Component } from 'react';
import Slider from 'rc-slider';
import { pathOr, assoc, dissoc, isEmpty, isNil } from 'ramda';

import withFieldWrapper from '../hocs/withFieldWrapper';

class Select extends Component {
    onChange = (value, index) => {
        const current = this.props.input.value || {};
        const values = !isNil(value) ? assoc(index, value, current) : dissoc(index, current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    render() {
        const { questions, input: { value }, language, translatedQuestions, disabled } = this.props;

        return questions.map((question, index) =>
            <div className='answer-row-slider' key={`question-${index}`}>
                <div className='answer-text' dangerouslySetInnerHTML={{ __html: language && translatedQuestions[index] ? translatedQuestions[index] : question }} />
                <div className="answer-slider-box">
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        disabled={disabled}
                        value={pathOr(0, [index], value)}
                        onChange={value => this.onChange(value, index)} />
                </div>
            </div>
        );
    }
}

export default withFieldWrapper(Select);
