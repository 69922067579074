import { createAsyncAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';

export const getRespondent = createAsyncAction(actions.GET_RESPONDENT, handlers.getRespondentHandler);

export const postRespondentStart = createAsyncAction(actions.POST_RESPONDENT_START, handlers.postRespondentStartHandler);
export const postRespondentFinish = createAsyncAction(actions.POST_RESPONDENT_FINISH, handlers.postRespondentFinishHandler);

export const putRespondentQuestion = createAsyncAction(actions.PUT_RESPONDENT_QUESTION, handlers.putRespondentQuestionHandler);
