import 'react-app-polyfill/ie11';
import 'string.prototype.startswith';
import 'ie-array-find-polyfill';
import 'core-js/modules/es6.array.find-index';

import React from 'react';
import { render } from 'react-dom';
import dotenv from 'dotenv';
import 'rc-slider/assets/index.css';
import './styles/select.css';
import './index.css';

import App from './components/App';
import configureStore from './store/configureStore';
import { setBaseUrl } from './utils/http';
import { provideResponseInterceptors, provideRequestInterceptors } from './utils/httpIntercaptors';
import { setTheme } from './utils/cssVariables';
import rollbar from './utils/rollbar';

setBaseUrl();
provideResponseInterceptors();
provideRequestInterceptors();
setTheme('ancorTheme');

const store = configureStore();

if (process.env.NODE_ENV === 'production') {
    rollbar({
        token: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

dotenv.config();

render(<App store={store} />, document.getElementById('root'));
