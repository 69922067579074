import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { pushRollbarError } from '../utils/rollbar';

const clearState = {
    error: false,
    path: '',
};

export default class RouteComponent extends Component {
    state = clearState;

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: true,
            path: this.props.path,
        });

        pushRollbarError(error, errorInfo);
    }

    componentDidUpdate() {
        if (this.state.error && this.props.path !== this.state.path) {
            this.setState(clearState);
        }
    }

    resetError = () => {
        window.history.back();
    }

    render() {
        return this.state.error ?
            <div style={{ textAlign: 'center', padding: 30 }}>
                <p>Не удалось отобразить данные</p>
                <button
                    className='btn'
                    onClick={this.resetError}>
                    Назад
                </button>
            </div>
        : <Route {...this.props} />;
    }
}
