import React, { Component } from 'react';
import { contains, without, append } from 'ramda';

import withQuestionFieldWrapper from '../hocs/withQuestionFieldWrapper';

class Individual extends Component {
    onChange = e => {
        const value = e.target.value;
        const current = this.props.value || [];
        const values = contains(value, current) ? without([value], current) : append(value, current);

        this.props.onChange(values.length ? values : null);
    }

    render() {
        const { value, scale, disabled } = this.props;

        return scale.map((item, index) =>
            <div className='my-2' key={`variant-${index}`}>
                <label className='checkbox'>
                    <input
                        type='checkbox'
                        value={item}
                        disabled={disabled}
                        checked={value ? contains(item, value || []) : false}
                        onChange={this.onChange} /><span className="checked-box" /> <span dangerouslySetInnerHTML={{ __html: item.name || item }} />
                </label>
            </div>
        );
    }
}

export default withQuestionFieldWrapper(Individual);
