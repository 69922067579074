import axios from 'axios';
import * as urls from '../../constants/urls';

export const getRespondentHandler = ({ payload }) => axios.get(urls.RESPONDENTS.stringify({ id: payload }));

export const postRespondentStartHandler = ({ payload: { id, ...data }}) => axios.post(urls.RESPONDENT_START.stringify({ id }), data);
export const postRespondentFinishHandler = ({ payload }) => axios.post(urls.RESPONDENT_FINISH.stringify({ id: payload }));

export const putRespondentQuestionHandler = ({ payload: { id, num, finish, ...data }}) => finish ?
    axios.put(urls.RESPONDENT_QUESTION.stringify({ id, num }), data).then(() => axios.post(urls.RESPONDENT_FINISH.stringify({ id }))) :
    axios.put(urls.RESPONDENT_QUESTION.stringify({ id, num }), data);
