import React, { Component } from 'react';
import { pathOr, assoc, dissoc, isEmpty } from 'ramda';

import withFieldWrapper from '../hocs/withFieldWrapper';

class Matrix extends Component {
    onChange = (value, index) => {
        const current = this.props.input.value || {};
        const values = value ? assoc(index, value, current) : dissoc(index, current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    render() {
        const { questions, input: { value }, language, translatedQuestions, disabled } = this.props;

        return questions.map((question, index) =>
            <div className='my-2' key={`question-${index}`}>
                <div className='mb-1' dangerouslySetInnerHTML={{ __html: language && translatedQuestions[index] ? translatedQuestions[index] : question }} />
                <textarea
                    value={pathOr('', [index], value)}
                    disabled={disabled}
                    onChange={e => this.onChange(e.target.value, index)} />
            </div>
        );
    }
}

export default withFieldWrapper(Matrix);
