import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { filter, find, propEq, contains, isNil, without, append } from 'ramda';

import withFieldWrapper from '../hocs/withFieldWrapper';
import { reorder, move } from '../../utils/dnd';

class Drag extends Component {
    state = {
        unselected: [],
        drag: false
    };

    componentDidMount() {
        this.setUnselected();
    }

    componentDidUpdate(prev) {
        if (prev.input.value === '' && this.props.input.value) {
            this.setUnselected();
        }
    }

    setUnselected = () => {
        const { value } = this.props.input;
        const items = this.getItems();

        this.setState({
            unselected: filter(item => !contains(item.id, value || []), items)
        });
    }

    onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        this.setState({ drag: true });

        const lists = {
            left: this.state.unselected,
            right: this.getSelectedItems()
        };

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                lists[source.droppableId],
                source.index,
                destination.index
            );

            if (source.droppableId === 'left') {
                this.setState({ unselected: items });
            } else {
                this.props.onChange(items.map(({ id }) => id));
            }
        } else {
            const result = move(
                lists[source.droppableId],
                lists[destination.droppableId],
                source,
                destination
            );
            const selected = result.right.map(({ id }) => id);

            this.setState({ unselected: result.left });
            this.props.onChange(selected.length ? selected : null);
        }

        this.setState({ drag: false });
    }

    getSelectedItems = () => {
        const { value } = this.props.input;
        const items = this.getItems();

        return filter(item => !!item, (value || []).map(i => find(propEq('id', i), items)));
    }

    getItems = () => {
        return this.props.questions.map((name, index) => ({ name, id: index }));
    }

    checkItem = e => {
        const values = this.props.input.value || [];
        const value = Number(e.target.value);

        this.props.onChange(contains(value, values) ? without([value], values) : append(value, values));
    }

    render() {
        const { language, translatedQuestions, options, input } = this.props;
        const selected = this.getSelectedItems();
        const items = this.getItems();
        const disabled = (!isNil(options.limitAnswers) && options.limitAnswers <= selected.length);

        return options.disableSort ?
            items.map(item =>
                <div key={item.id}>
                    <label className='checkbox drag-field'>
                        <input
                            type='checkbox'
                            checked={contains(item.id, input.value || [])}
                            value={item.id}
                            onChange={this.checkItem}
                            disabled={disabled && !contains(item.id, input.value || [])} />
                        <span className='checked-box' /> <span>{ language && translatedQuestions[item.id] ? translatedQuestions[item.id] : item.name }</span>
                    </label>
                </div>
            ) :
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className='drag-wrap'>
                    <Droppable droppableId='left'>
                        { provided =>
                            <div className='drag-col' ref={provided.innerRef}>
                                { this.state.unselected.map((item, index) =>
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        { provided =>
                                            <div
                                                className='drag-box'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <div dangerouslySetInnerHTML={{ __html: language && translatedQuestions[item.id] ? translatedQuestions[item.id] : item.name }} />
                                            </div>
                                        }
                                    </Draggable>
                                )}
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                    <Droppable droppableId='right' isDropDisabled={disabled || (this.state.drag && !isNil(options.limitAnswers) && options.limitAnswers <= selected.length - 1)}>
                        { provided =>
                            <div ref={provided.innerRef} className='drag-col'>
                                { selected.map((item, index) =>
                                    <Draggable
                                        className='drag-box'
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        { provided =>
                                            <div
                                                className='drag-box'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <div dangerouslySetInnerHTML={{ __html: language && translatedQuestions[item.id] ? translatedQuestions[item.id] : item.name }} />
                                            </div>
                                        }
                                    </Draggable>
                                )}
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                </div>
            </DragDropContext>;
    }
}

export default withFieldWrapper(Drag);
