import React, { Component } from 'react';
import { head } from 'ramda';

export default WrappedComponent =>
    class QuestionFieldWrapper extends Component {
        onChange = value => this.props.input.onChange(value ? [value] : null);

        render() {
            const { input: { value }} = this.props;

            return <WrappedComponent
                {...this.props}
                onChange={this.onChange}
                value={value ? head(value) : null} />;
        }
    }
