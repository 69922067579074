import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const RESPONDENTS = construct('/public/respondents/:id');
export const RESPONDENT_START = construct('/public/respondents/:id/start');
export const RESPONDENT_FINISH = construct('/public/respondents/:id/finish');
export const RESPONDENT_QUESTION = construct('/public/respondents/:id/questions/:num');
