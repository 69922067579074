import React, { Component } from 'react';

import withFieldWrapper from '../hocs/withFieldWrapper';

class Checkbox extends Component {
    onChange = e => this.props.onChange(e.target.checked);

    render() {
        const { input: { value }, label } = this.props;

        return <label className='checkbox checkbox-field'>
            <input
                type='checkbox'
                checked={!!value}
                onChange={this.onChange} />
            <span className='checked-box' /> <div dangerouslySetInnerHTML={{ __html: label }} />
        </label>;
    }
}

export default withFieldWrapper(Checkbox);
