import React, { Component } from 'react';
import { is, path } from 'ramda';
import Select, { Option } from 'rc-select';

import withFieldWrapper from '../hocs/withFieldWrapper';

class SelectComponent extends Component {
    onChange = value => this.props.onChange(value);

    render() {
        const { input: { value, name }} = this.props;
        const options = is(Array, this.props.options) ? this.props.options : [];

        return (
            <Select
                value={value}
                notFoundContent='Ничего не найдено'
                onChange={this.onChange}
                showSearch
                defaultActiveFirstOption={false}
                filterOption={(input, option) =>
                    (option.children || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value=''></Option>
                { options.map((option, index) => (
                    <Option key={`${name}-${index}`} value={option}>{option}</Option>
                ))}
            </Select>
        );
    }
}

class SelectNative extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value, name }} = this.props;
        const options = is(Array, this.props.options) ? this.props.options : [];

        return <select value={value} onChange={this.onChange}>
            <option></option>
            { options.map((opt, index) =>
                <option key={`${name}-${index}`} value={opt}>
                    { opt }
                </option>
            )}
        </select>
    }
}

export default withFieldWrapper((props) => {
    if (path(['options', 'length'], props) > 10) {
        return <SelectComponent {...props} />;
    } else {
        return <SelectNative {...props} />;
    }
});
